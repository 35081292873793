import Vue from 'vue'
import Vuex from 'vuex'

import common from './modules/common'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer(val) {
    return {
      common: {
        versionData:val.common.versionData,
        currentVersion:val.common.currentVersion,
        courseData:val.common.courseData,
        pressData:val.common.pressData,
        menuTypeData:val.common.menuTypeData,
        generalMenuData:val.common.generalMenuData
        
      }

    }
  }
})
const store = new Vuex.Store({
  strict: false,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    common,
  },
  plugins: [vuexLocal.plugin]

})

export default store;
