import http from "../http";

const book = {
  addBookClass: function (params) {
    return http.post("/backend/api/v1/bookclass/add", params);
  },
  getBookClass: function (params) {
    return http.get("/backend/api/v1/bookclass", params);
  },
  detailBookClass: function (params) {
    return http.get("/backend/api/v1/bookclass", params);
  },
  editBookClass: function (params) {
    return http.post("/backend/api/v1/bookclass/update", params);
  },
  deleteBookClass: function (params) {
    return http.delete("/backend/api/v1/bookclass/delete", params);
  },

  addBookList: function (params) {
    return http.post("/backend/api/v1/books/add", params);
  },
  getBookList: function (params) {
    return http.get("/backend/api/v1/books", params);
  },
  detailBookList: function (params) {
    return http.get("/backend/api/v1/books", params);
  },
  editBookList: function (params) {
    return http.post("/backend/api/v1/books/update", params);
  },
  deleteBookList: function (params) {
    return http.delete("/backend/api/v1/books/delete", params);
  },


  addBookChapter: function (params) {
    return http.post("/backend/api/v1/bookchapter/add", params);
  },
  getBookChapter: function (params) {
    return http.get("/backend/api/v1/bookchapter", params);
  },
  detailBookChapter: function (params) {
    return http.get("/backend/api/v1/bookchapter", params);
  },
  editBookChapter: function (params) {
    return http.post("/backend/api/v1/bookchapter/update", params);
  },
  deleteBookChapter: function (params) {
    return http.delete("/backend/api/v1/bookchapter/delete", params);
  },

  addBookContent: function (params) {
    return http.post("/backend/api/v1/bookcontent/add", params);
  },
  getBookContent: function (params) {
    return http.get("/backend/api/v1/bookcontent/edit", params);
  },
  editBookContent: function (params) {
    return http.post("/backend/api/v1/bookcontent/update", params);
  },
};

export default book;
