import http from "../http";

const user = {
  addUser: function (params) {
    return http.post("/backend/api/v1/member/add", params);
  },
  getUser: function (params) {
    return http.get("/backend/api/v1/member", params);
  },
  editUser: function (params) {
    return http.post("/backend/api/v1/member/update", params);
  },
  deleteUser: function (params) {
    return http.delete("/backend/api/v1/member/delete", params);
  },
};

export default user;
