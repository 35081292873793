import Vue from 'vue'
import App from './App.vue'
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'

//样式
import 'tinymce/skins/content/default/content.min.css'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'

//主题
import 'tinymce/themes/silver'

//插件
import 'tinymce/plugins/link' //链接插件
import 'tinymce/plugins/image' //图片插件
import 'tinymce/plugins/media' //媒体插件
import 'tinymce/plugins/table' //表格插件
import 'tinymce/plugins/lists' //列表插件
import 'tinymce/plugins/quickbars' //快速栏插件
import 'tinymce/plugins/fullscreen' //全屏插件
import 'tinymce/plugins/hr' //分割线
import 'tinymce/plugins/charmap' //特殊符号
//import 'tinymce/plugins/indent2em' //首行缩进//  3.13注释
import 'tinymce/plugins/preview' //预览 
import 'tinymce/plugins/code' //预览 
//import 'tinymce/plugins/importword' //导入word文档  //3.13注释

/**
 * 注：
 * 5.3.x版本需要额外引进图标，没有所有按钮就会显示not found
 */
import 'tinymce/icons/default/icons'

//本地化
import './utils/tinymce/langs/zh_CN.js'

import moment from 'moment';
import 'moment/locale/zh-cn'; // 获取中国标准时间，避免出现utc时间



Vue.prototype.$moment = moment


Vue.prototype.$tinymce = tinymce
Vue.use(VueTinymce)

Vue.config.productionTip = false

import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
import api from "@/api/index.js"
import ElementUI from 'element-ui'
import './theme/element/index.css'

import layerPage from '@/components/layerPage'
import uploadFile from '@/components/uploadFile'
import menuTree from '@/components/menuTree'

import {deepClone} from '@/utils/public'


Vue.use(VueAxios, axios)
Vue.use(ElementUI);

Vue.prototype.$axios = axios
Vue.prototype.$api = api
Vue.prototype.$eventBus = new Vue()

Vue.prototype.$deepClone=deepClone

Vue.component('layerPage',layerPage)
Vue.component('uploadFile',uploadFile)
Vue.component('menuTree',menuTree)


Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
