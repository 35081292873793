import http from "../http";

const generalMenu = {

  addGeneralMenu: function (params) {
    return http.post("/backend/api/v1/generalmenu/add", params);
  },
  getGeneralMenu: function (params) {
    return http.get("/backend/api/v1/generalmenu", params);
  },
  getGeneralMenuAll: function (params) {
    return http.get("/backend/api/v1/generalmenu/all", params);
  },
  
  detailGeneralMenu: function (params) {
    return http.get("/backend/api/v1/generalmenu", params);
  },
  editGeneralMenu: function (params) {
    return http.post("/backend/api/v1/generalmenu/update", params);
  },
  deleteGeneralMenu: function (params) {
    return http.delete("/backend/api/v1/generalmenu/delete", params);
  },
};

export default generalMenu;
