import http from "../http";

const menuType = {
  addMenuType: function (params) {
    return http.post("/backend/api/v1/menutype/add", params);
  },
  getMenuType: function (params) {
    return http.get("/backend/api/v1/menutype", params);
  },
  getMenuTypeAll: function (params) {
    return http.get("/backend/api/v1/menutype/all", params);
  },
  
  detailMenuType: function (params) {
    return http.get("/backend/api/v1/menutype", params);
  },
  editMenuType: function (params) {
    return http.post("/backend/api/v1/menutype/update", params);
  },
  deleteMenuType: function (params) {
    return http.delete("/backend/api/v1/menutype/delete", params);
  }
};

export default menuType;
