<template>
  <div class="custom-menu-tree">
    <div class="loading-box" v-if="loadingTree">
      <i class="el-icon-loading"></i>
    </div>
    <el-tree
      v-else
      :data="menuData"
      show-checkbox
      node-key="id"
      accordion
      :check-strictly="checkStrictly"
      :default-checked-keys="checkedData"
      :props="defaultProps"
      @check="checkChange"
    >
    </el-tree>
  </div>
</template>

<script>
export default {
  
  name: "customMenuTree",
  props: {
    checkedKeys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: "children",
          label: "name",
        };
      },
    },
    checkStrictly:{
      type:Boolean,
      default:()=>{
        return true
      }
    }
  },
  watch:{
    checkedKeys:function(val){
      this.checkedData = val;
    }
  },
  data() {
    return {
      loadingTree:false,
      menuData: [],
      checkedData:[]
    };
  },
  created() {
    this.checkedData = this.checkedKeys||[];
    this.loadingTree=true;
    this.$api.menuTreeApi().then((res) => {
      if (res.status == 0) {
        this.menuData=res.data
      } else {
        this.$message({
          type: "warning",
          message: res.message,
        });
      }
      this.loadingTree = false;
    });
  },
  methods: {
    checkChange(data,status,children) {
      this.$emit('changeSelect',status.checkedKeys)
    },
  },
};
</script>

<style  lang="scss" scoped>
.loading-box{
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  i{
    font-size: 20px;
     color: #008d71;
  }
}
</style>