import http from "../http";

const acount = {
  /*文件上传*/
  addAcount: function (params) {
    return http.post("/backend/api/v1/administrator/add", params);
  },
  getAcount: function (params) {
    return http.get("/backend/api/v1/administrator", params);
  },
  detailAcount: function (params) {
    return http.get("/backend/api/v1/administrator", params);
  },
  editAcount: function (params) {
    return http.post("/backend/api/v1/administrator/update", params);
  },
  deleteAcount: function (params) {
    return http.delete("/backend/api/v1/administrator/delete", params);
  },
};

export default acount;
