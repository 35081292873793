import http from "../http";

const press = {
  addPress: function (params) {
    return http.post("/backend/api/v1/press/add", params);
  },
  getPress: function (params) {
    return http.get("/backend/api/v1/press", params);
  },
  getPressAll: function (params) {
    return http.get("/backend/api/v1/press/all", params);
  },
  detailPress: function (params) {
    return http.get("/backend/api/v1/role", params);
  },
  editPress: function (params) {
    return http.post("/backend/api/v1/press/update", params);
  },
  deletePress: function (params) {
    return http.delete("/backend/api/v1/press/delete", params);
  },
};

export default press;
