import http from "../http";

const famouse = {
  /*文件上传*/
  addFamouse: function (params) {
    return http.post("/backend/api/v1/coursecontent/add", params);
  },
  getFamouse: function (params) {
    return http.get("/backend/api/v1/coursecontent/all", params);
  },
  detailFamouse: function (params) {
    return http.get("/backend/api/v1/coursecontent/edit", params);
  },
  editFamouse: function (params) {
    return http.post("/backend/api/v1/coursecontent/update", params);
  },
  deleteFamouse: function (params) {
    return http.delete("/backend/api/v1/coursecontent/delete", params);
  },
};

export default famouse;
