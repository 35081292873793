import http from "../http";

const course = {
  /*文件上传*/
  addCourse: function (params) {
    return http.post("/backend/api/v1/subject/add", params);
  },
  getCourse: function (params) {
    return http.get("/backend/api/v1/subject", params);
  },
  getCourseAll: function (params) {
    return http.get("/backend/api/v1/subject/all", params);
  },
  detailCourse: function (params) {
    return http.get("/backend/api/v1/subject", params);
  },
  editCourse: function (params) {
    return http.post("/backend/api/v1/subject/update", params);
  },
  deleteCourse: function (params) {
    return http.delete("/backend/api/v1/subject/delete", params);
  },

  getCourseInnerList:function(params){
    return http.get("/backend/api/v1/course/all", params);
  },
  addCourseInner:function(params){
    return http.post("/backend/api/v1/course/add", params);
  },
  editCourseInner:function(params){
    return http.post("/backend/api/v1/course/update", params);
  },
  deleteCourseInner:function(params){
    return http.delete("/backend/api/v1/course/delete", params);
  },
  //高频词汇绑定查询
  getGaopincihuisearch:function(params){
    return http.get("/backend/api/v1/course/gaopincihuisearch", params);
  },
  //高频词汇添加
  addGaopincihui:function(params){
    return http.post("/backend/api/v1/course/addgaopincihui", params);
  },

};

export default course;
