import http from "../http";

const test = {
  /*文件上传*/
  addTest: function (params) {
    return http.post("/backend/api/v1/truetopic/add", params);
  },
  getTest: function (params) {
    return http.get("/backend/api/v1/truetopic", params);
  },
  detailTest: function (params) {
    return http.get("/backend/api/v1/truetopic/edit", params);
  },
  editTest: function (params) {
    return http.post("/backend/api/v1/truetopic/update", params);
  },
  deleteTest: function (params) {
    return http.delete("/backend/api/v1/truetopic/delete", params);
  },
};

export default test;
