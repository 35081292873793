import Vue from "vue";
import VueRouter from "vue-router";

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
//重写push方法
VueRouter.prototype.push = function (location, res, rej) {
  if (res && rej) {
    originPush.call(this, location, res, rej);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
//重写replace方法
VueRouter.prototype.replace = function (location, res, rej) {
  if (res && rej) {
    originReplace.call(this, location, res, rej);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "首页",
    component: () => import("@/views/index.vue"),
    children: [
      {
        path: "/",
        name: "首页",
        component: () => import("@/views/moudle/index/index.vue"),
      },
      {
        path: "/version",
        name: "版本管理",
        component: () => import("@/views/moudle/version/index.vue"),
      },
      {
        path: "/press",

        name: "出版管理",
        component: () => import("@/views/moudle/press/index.vue"),
      },
      {
        path: "/menuType",
        name: "菜单类型",
        component: () => import("@/views/moudle/menuType/index.vue"),
      },
      {
        path: "/generalMenu",
        name: "通用菜单",
        component: () => import("@/views/moudle/generalMenu/index.vue"),
      },
      {
        path: "/user",
        name: "用户管理",
        component: () => import("@/views/moudle/user/index.vue"),
      },
      {
        path: "/store",
        name: "库存管理",
        component: () => import("@/views/moudle/store/index.vue"),
      },
      {
        path: "/test",
        name: "真题管理",
        meta:{
          showAdd:false
        },
        component: () => import("@/views/moudle/test/index.vue"),
      },
      {
        path: "/course",
        name: "科目管理",
        meta:{
          showAdd:true
        },
        component: () => import("@/views/moudle/course/index.vue"),
      },
      {
        path: "/book",
        name: "图书管理",
        component: () => import("@/views/moudle/book/index.vue"),
      },
      {
        path: "/banner",
        name: "广告管理",
        component: () => import("@/views/moudle/banner/index.vue"),
      },
      {
        path: "/notify",
        name: "消息管理",
        component: () => import("@/views/moudle/notify/index.vue"),
      },
      {
        path: "/acount",
        name: "账号管理",
        component: () => import("@/views/moudle/acount/index.vue"),
      },
      {
        path: "/material",
        name: "素材管理",
        component: () => import("@/views/moudle/material/index.vue"),
      },
      {
        path: "/questionBank",
        name: "题库管理",
        component: () => import("@/views/moudle/questionBank/index.vue"),
      },
      // {
      //   path: "/readingBank",
      //   name: "朗读管理",
      //   component: () => import("@/views/moudle/readingBank/index.vue"),
      // },
      {
        path: "/wordsBank",
        name: "内容管理",
        component: () => import("@/views/moudle/wordsBank/index.vue"),
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },

  {
    path: "*",
    name: "errorPage",
    component: () => import("@/views/errorPage.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return { x: 0, y: to.meta.savedPosition || 0 };
    }
  },
});

//判断是否登录
router.beforeEach(function (to, from, next) {
  let token = window.sessionStorage.getItem("token");
  if (to.path != "/login") {
    //通过查看token判断是否登录
    if (token && token != "") {
      next(); //表示已经登录
    } else {
      //next可以传递一个路由对象作为参数 表示需要跳转到的页面
      next({
        name: "login",
      });
    }
  } else {
    //表示不需要登录
    next(); //继续往后走
  }
});

export default router;
