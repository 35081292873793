<template>
  <div id="app">
    <router-view />
    <div class="loading-box" v-if="isLoading">
      <i class="el-icon-loading"></i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapGetters({
      token: "common/getToken",
    }),
  },
  watch: {
    token: function (val) {
      if (val) {
        this.initData();
      }
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    if (this.token) {
      this.initData();
    }
  },
  mounted() {
    this.$eventBus.$on("startLoading", () => {
      this.isLoading = true;
    });
    this.$eventBus.$on("endLoading", () => {
      this.isLoading = false;
    });
    this.$eventBus.$on("refreshAction", (val) => {
      switch (val) {
        case "version": {
          this.initVersion();
          break;
        }
        case "press": {
          this.initPress();
          break;
        }
        case "menuType": {
          this.initMenuType();
          break;
        }
        case "generalMenu": {
          this.initGeneralMenu();
          break;
        }
        default: {
          this.initCourse();
        }
      }
    });
    // window.addEventListener("resize", () => {
    //   window.location.reload();
    // });
  },
  destroyed() {},
  methods: {
    initData() {
      this.initVersion();
      this.initCourse();
      this.initPress();
      // this.initMenuType();
      this.initGeneralMenu();
      this.initManageType();
    },
    initManageType() {
      this.$api.manageTyle().then((res) => {
        if (res.status != 0) {
          this.$message({
            type: "error",
            message: res.message,
          });
          return false;
        }
        this.$store.commit("common/setAcountTypeData", res.data.data);
      });
    },
    initVersion() {
      this.$api.getVersionAll().then((res) => {
        if (res.status != 0) {
          this.$message({
            type: "error",
            message: res.message,
          });
          return false;
        }
        this.$store.commit("common/setVersionData", res.data.data);
      });
    },
    initCourse() {
      this.$api.getCourseAll().then((res) => {
        if (res.status != 0) {
          this.$message({
            type: "error",
            message: res.message,
          });
          return false;
        }
        this.$store.commit("common/setCourseData", res.data.data);
      });
    },
    initPress() {
      this.$api.getPressAll().then((res) => {
        if (res.status != 0) {
          this.$message({
            type: "error",
            message: res.message,
          });
          return false;
        }
        this.$store.commit("common/setPressData", res.data.data);
      });
    },
    initMenuType() {
      this.$api.getMenuTypeAll().then((res) => {
        if (res.status != 0) {
          this.$message({
            type: "error",
            message: res.message,
          });
          return false;
        }
        this.$store.commit("common/setMenuTypeData", res.data.data);
      });
    },
    initGeneralMenu() {
      this.$api.getGeneralMenuAll().then((res) => {
        if (res.status != 0) {
          this.$message({
            type: "error",
            message: res.message,
          });
          return false;
        }
        this.$store.commit("common/setGeneralMenuData", res.data.data);
      });
    },
  },
};
</script>

<style lang="scss">
@import "assets/iconfont/iconfont.css";
@import "assets/style/commonStyle.scss";
.el-dialog__header {
  padding: 15px !important;
  border-bottom: 1px #ccc solid;
}
.el-dialog__footer {
  padding: 15px !important;
  border-top: 1px #ccc solid;
}
</style>
<style lang="scss" scoped>
$fontColor: #008d71;
.loading-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000000;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 40px;
    color: $fontColor;
  }
}
</style>
