<template>
  <div class="upload-box">
    <div class="upload-item">
      <el-upload
        class="upload-demo"
        action="https://gzapi.jingdian985.com/backend/api/v1/media/add"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :before-remove="beforeRemove"
        :file-list="fileList"
        :accept="addeptType"
        :data="{ from: uploadFileType,bindid:bindId }"
        :headers="requestHeaders"
      >
        <el-button style="width: 80px" size="mini" type="primary"
          >点击上传</el-button
        >
        <el-button
          v-if="hasSelect"
          style="width: 80px"
          size="mini"
          type="primary"
          @click.stop="dialogStatus = true"
          >点击选择</el-button
        >
        <el-button size="mini" type="primary" @click.stop="previewMedia"
          >预览</el-button
        >
      </el-upload>
    </div>

    <el-dialog
      title="选择素材"
      fullscreen
      append-to-body
      :visible.sync="dialogStatus"
    >
      <material-list
        v-if="dialogStatus"
        @selectRow="selectRow"
        :fileType="uploadFileType"
        :selectMateral="true"
        :isShow="1"
      ></material-list>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogStatus = false">取消</el-button>
        <el-button size="mini" type="primary" @click="confirmMatieral"
          >确认</el-button
        >
      </div>
    </el-dialog>

    <!-- 预览组件 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false;
        }
      "
      :url-list="[fileSrc]"
    >
    </el-image-viewer>

    <el-dialog
      title="视频预览"
      fullscreen
      append-to-body
      :visible.sync="dialogVideoStatus"
      @close="closePreviewVideo"
    >
      <div class="preview-vidoe-box">
        <div id="dplayerCon" class="dplayerCon" ref="dplayerCon"></div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="closePreviewVideo"
          >关闭</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="文件预览" fullscreen :visible.sync="dialogFileStatus">
      <div class="preview-vidoe-box">
        <div id="docxPreview" class="docxPreview" ref="docxPreview"></div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="dialogFileStatus = false"
          >关闭</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="音频预览"
      fullscreen
      append-to-body
      :visible.sync="dialogAudioStatus"
      @close="closePreviewAudio"
    >
      <div class="preview-vidoe-box">
        <div class="preview-audio">
          <audioPlayer
            :audioSrc="fileSrc"
            :status="dialogAudioStatus"
          ></audioPlayer>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="closePreviewAudio"
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import DPlayer from "dplayer";
import { jointPath } from "@/utils/public";
let docx = require("docx-preview");
export default {
  name: "uploadFile",
  components: {
    ElImageViewer,
    materialList: () => import("@/views/moudle/material/index.vue"),
    audioPlayer: () => import("@/components/audioPlayer.vue"),
  },
  props: {
    fileLabel: {
      type: String,
      default: "",
    },
    uploadFileType: {
      type: [String, Number],
      default: 1,
    },
    fileList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    width: {
      type: [String, Number],
      default: 193,
    },
    fileType: {
      type: [String, Number],
      default: 1,
    },

    hasSelect: {
      type: Boolean,
      default: true,
    },
    defaultFile: {
      type: String,
      default: "",
    },
    bindId:{
      type:[Number,String],
      default:''
    }
  },
  watch: {
    defaultFile: function (val) {
      this.fileSrc = this.jointPath(val);
    },
    uploadFileType: {
      handler: function (val) {
        switch (val) {
          case 1: {
            this.addeptType = "image/*";
            break;
          }
          case 2: {
            this.addeptType = "audio/*";
            break;
          }
          case 3: {
            this.addeptType = "video/*";
            break;
          }
          case 4: {
            this.addeptType = "swf/*";
            break;
          }
          default: {
            this.addeptType = "application/*";
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      materialType: "common/getMaterialType",
    }),
  },
  data() {
    return {
      requestHeaders: {
        Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
      },
      addeptType: "image/*",
      dialogStatus: false,
      showViewer: false,
      dialogAudioStatus: false,
      dialogVideoStatus: false,
      dialogFileStatus: false,
      fileSrc: "",
      fileObj: {
        name: "",
        fileType: 1,
      },

      apiUrl: {
        add: this.$api.addMaterial,
        get: this.$api.getMaterial,
        detail: this.$api.detailMaterial,
        edit: this.$api.editMaterial,
        delete: this.$api.deleteMaterial,
      },
      selectObj: {},
      videoPlayer: null,
    };
  },
  created() {
    this.$set(this.fileObj, "fileType", this.fileType);
    this.fileSrc = this.jointPath(this.defaultFile);
  },
  mounted() {},
  methods: {
    jointPath,
    closePreviewAudio() {
      this.dialogAudioStatus = false;
      this.audioSrc = "";
    },
    closePreviewVideo() {
      this.dialogVideoStatus = false;
      this.videoPlayer.pause();
    },
    previewMedia() {
      let that = this;
      if (!that.fileSrc.split('.com/')[1]) {
        this.$message({
          type: "warning",
          message: "暂无可预览文件，请先上传或者点击选择！",
        });
        return false;
      }
      if (that.uploadFileType == 1) {
        that.showViewer = true;
      }
      if (that.uploadFileType == 2) {
        that.dialogAudioStatus = true;
      }
      if (that.uploadFileType == 3) {
        that.dialogVideoStatus = true;
        that.$nextTick(() => {
          let dplayerContent = that.$refs["dplayerCon"];
          that.videoPlayer = new DPlayer({
            container: dplayerContent,
            autoplay: true,
            video: {
              url: that.fileSrc,
            },
          });
        });
      }
      if (that.uploadFileType == 5) {
        that.$message({
          type: "warning",
          message: "暂未开发",
        });
        // that.dialogFileStatus = true;
        // that.$nextTick(()=>{
        //   docx.renderAsync(row.url, that.$refs["docxPreview"]);
        // })
      }
    },
    selectRow(obj) {
      this.selectObj = obj;
    },
    confirmMatieral() {
      this.dialogStatus = false;
      this.fileSrc = this.jointPath(this.selectObj.path);
      this.$emit("confirmMatieral", {
        label: this.fileLabel,
        path: this.selectObj.path,
      });
    },
    previewImg() {
      this.showViewer = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      this.$emit("uploaded", file);
    },
    handleSuccess(res, file) {
      this.$emit("uploaded", res.data.path);
      this.fileSrc = res.data.url;
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-audio {
  width: 300px;
}
.dplayerCon {
  height: 100%;
}
.preview-vidoe-box {
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 40px;
}
.upload-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label {
    width: 100px;
    text-align: right;
  }
  .con {
    width: 193px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ::v-deep .upload-demo {
    width: 100%;
  }
  ::v-deep .el-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
  }
}
</style>