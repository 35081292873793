import http from "../http";

const version = {
  /*文件上传*/
  addVersion: function (params) {
    return http.post("/backend/api/v1/role/add", params);
  },
  getVersion: function (params) {
    return http.get("/backend/api/v1/role", params);
  },
  getVersionAll: function (params) {
    return http.get("/backend/api/v1/role/all", params);
  },
  detailVersion: function (params) {
    return http.get("/backend/api/v1/role", params);
  },
  editVersion: function (params) {
    return http.post("/backend/api/v1/role/update", params);
  },
  deleteVersion: function (params) {
    return http.delete("/backend/api/v1/role/delete", params);
  },
};

export default version;
