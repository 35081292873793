import http from "../http";

const banner = {
  /*文件上传*/
  addBanner: function (params) {
    return http.post("/backend/api/v1/banner/add", params);
  },
  getBanner: function (params) {
    return http.get("/backend/api/v1/banner", params);
  },
  detailBanner: function (params) {
    return http.get("/backend/api/v1/banner", params);
  },
  editBanner: function (params) {
    return http.post("/backend/api/v1/banner/update", params);
  },
  deleteBanner: function (params) {
    return http.delete("/backend/api/v1/banner/delete", params);
  },
};

export default banner;
