import http from "../http";

const questionBank = {
  /*文件上传*/
  addQuestionBank: function (params) {
    return http.post("/backend/api/v1/questionbank/add", params);
  },
  getQuestionBank: function (params) {
    return http.get("/backend/api/v1/questionbank", params);
  },
  detailQuestionBank: function (params) {
    return http.get("/backend/api/v1/questionbank", params);
  },
  editQuestionBank: function (params) {
    return http.post("/backend/api/v1/questionbank/update", params);
  },
  deleteQuestionBank: function (params) {
    return http.delete("/backend/api/v1/questionbank/delete", params);
  },
};

export default questionBank;
