import http from "../http";

const store = {
  /*文件上传*/
  addStore: function (params) {
    return http.post("/backend/api/v1/activation/add", params);
  },
  getStore: function (params) {
    return http.get("/backend/api/v1/activation", params);
  },
  detailStore: function (params) {
    return http.get("/backend/api/v1/activation", params);
  },
  editStore: function (params) {
    return http.post("/backend/api/v1/activation/update", params);
  },
  deleteStore: function (params) {
    return http.delete("/backend/api/v1/activation/delete", params);
  },
  exportStore: function (params) {
    return http.get("/backend/api/v1/activation/export", params);
  }, 
};

export default store;
