import http from "../http";

const material = {
  addMaterial: function (params) {
    return http.post("/backend/api/v1/media/add", params);
  },
  getMaterial: function (params) {
    return http.get("/backend/api/v1/media", params);
  },
  detailMaterial: function (params) {
    return http.get("/backend/api/v1/media", params);
  },
  editMaterial: function (params) {
    return http.post("/backend/api/v1/media/update", params);
  },
  deleteMaterial: function (params) {
    return http.delete("/backend/api/v1/media/delete", params);
  },
};

export default material;
