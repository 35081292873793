const state = {
  token:'',
  userData: {},
  versionData: [],
  pressData: [],
  currentVersion: 2,
  courseData: [],
  gradeData: [
	  {
	    name:'一年级',
	    id:'一年级',
	  },{
	    name:'二年级',
	    id:'二年级',
	  },{
	    name:'三年级',
	    id:'三年级',
	  },
  ],
  menuTypeData: [
    {
      name:'父级菜单',
      id:1,
      url:''
    },
    {
      name:'图文内容落地',
      id:2,
      url:''
    },
    {
      name:'视频列表落地',
      id:3,
      url:''
    },
    {
      name:'朗读内容落地',
      id:4,
      url:''
    },
    {
      name:'文学常识落地',
      id:5,
      url:''
    },
    {
      name:'试题内容落地',
      id:6,
      url:''
    },
    {
      name:'单词内容落地',
      id:7,
      url:''
    },
    {
      name:'跳转至频道页',
      id:8,
      url:'/channel'
    },
    {
      name:'真题内容落地',
      id:9,
      url:''
    },
    {
      name:'Flash内容落地',
      id:10,
      url:''
    },
    {
      name:'自定义模块',
      id:11,
      url:'/customMoudle'
    },
  ],
  generalMenuData: [],
  acountType:[],
  pageData: {},
  classType:[
    {
      label:'普通文章',
      value:1
    },
    {
      label:'诗歌文章',
      value:2
    },
   
  ],
  questionType: [
    {
      label: "单选题",
      value: 1,
    },
    {
      label: "多选题",
      value: 2,
    },
    {
      label: "问答题",
      value: 3,
    },
  ],
  materialType: [
    {
      label: "图片",
      value: 1,
    },
    {
      label: "音频",
      value: 2,
    },
    {
      label: "视频",
      value: 3,
    },
    {
      label: "Flash",
      value: 4,
    },
    {
      label: "其他",
      value: 5,
    },
  ],
};
const getters = {
  getToken(state){
    return state.token;
  },
  getUserData(state) {
    return state.userData;
  },
  getVersionData(state) {
    return state.versionData;
  },
  getCurrentVersion(state) {
    return state.currentVersion;
  },
  getCourseData(state) {
    return state.courseData;
  },
  getGradeData(state) {
    return state.gradeData;
  },
  getMenuTypeData(state) {
    return state.menuTypeData;
  },
  getPressData(state) {
    return state.pressData;
  },
  getQuestionType(state) {
    return state.questionType;
  },
  getMaterialType(state) {
    return state.materialType;
  },
  getGeneralMenuData(state) {
    return state.generalMenuData;
  },
  getPageData(state) {
    return state.pageData;
  },
  getAcountTypeData(state){
    return state.acountType;
  },
  getClassType(state){
    return state.classType
  }
};
const actions = {};
const mutations = {
  setToken(state,data){
    state.token = data;
  },
  setVersionData(state, data) {
    state.versionData = data
  },
  setCurrentVersion(state, data) {
    state.currentVersion = data;
  },
  setCourseData(state, data) {
    state.courseData = data;
  },
  setMenuTypeData(state, data) {
    state.menuTypeData = data;
  },
  setPressData(state, data) {
    state.pressData = data;
  },
  setQuestionType(state, data) {
    state.questionType = data;
  },
  setMaterialType(state, data) {
    state.materialType = data;
  },
  setUserData(state, data) {
    state.userData = data;
  },
  setGeneralMenuData(state, data) {
    state.generalMenuData = data;
  },
  setPageData(state, data) {
    state.pageData = data;
  },
  setAcountTypeData(state,data){
    state.acountType = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
