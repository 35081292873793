<template>
  <div class="layer-box" :class="{fullScreen:fullScreen}">
    <div class="layer-header">
      <i class="el-icon-close" @click="closeLayer"></i>
      <ul class="layer-title-list" v-if="layerTitleList.length">
        <li v-for="(item, index) in layerTitleList" :key="index">{{ item }}</li>
      </ul>
      <div class="layer-title" v-else>
        {{
          layerTitle
            ? layerTitle
            : layerType == "new"
            ? "新增"
            : layerType == "edit"
            ? "编辑"
            : "查看"
        }}
      </div>
    </div>
    <div class="layer-body">
      <slot name="layerBody"></slot>
    </div>
    <div class="layer-footer">
      <template v-if="['new', 'edit'].includes(layerType)">
        <el-button size="mini" @click="closeLayer">取消</el-button>
        <el-button
          size="mini"
          type="primary"
          :loading="requestIng"
          :disabled="requestIng"
          @click="confirmAction"
          >确认</el-button
        >
      </template>
      <template v-else-if="['view'].includes(layerType)">
        <el-button size="mini" type="primary" @click="closeLayer"
          >关闭</el-button
        >
      </template>
      <template v-else>
        <slot name="layerFooter"></slot>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "layerPage",
  props: {
    fullScreen:{
      type:Boolean,
      default:()=>{
        return false
      }
    },
    layerTitleList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    layerType: {
      type: [String, Number],
      default: 1,
    },
    layerTitle: {
      type: String,
      default: "",
    },
    titleList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    requestIng: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      showLayer: false,
    };
  },
  created() {},
  methods: {
    closeLayer() {
      this.$emit("closeLayer");
    },
    confirmAction() {
      this.$emit("confirmAction");
    },
  },
};
</script>

<style lang="scss" scoped>
.layer-title-list {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  line-height: 50px;
  li {
    padding: 0 2px;
    &::after{
      content: ' /';
    }
    &:last-child {
      color: #f00;
      &::after{
        content:''
      }
    }
  }
}
::-webkit-scrollbar {
  background-color: #fff;
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  opacity: 0;
}
.layer-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .layer-header {
    position: relative;
    width: 100%;
    height: 50px;
    min-height: 50px;
    border-bottom: 1px #ccc solid;
    box-shadow: 0 3px 3px #e5e5e5;
    .el-icon-close {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 30px;
    }
    .layer-title {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 50px;
      height: 100%;
      font-size: 15px;
    }
  }
  .layer-body {
    width: 100%;
    height: calc(100% - 100px);
    overflow: hidden auto;
  }
  .layer-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 50px;
    min-height: 50px;
    padding: 0 20px;
    border-top: 1px #ccc solid;
    box-shadow: 0 -3px 3px #e5e5e5;
  }
}
.layer-box.fullScreen{
  position: fixed;
}
</style>