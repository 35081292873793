import http from "../http";

const questionBank = {
  /*文件上传*/
  addWordsBank: function (params) {
    return http.post("/backend/api/v1/word/add", params);
  },
  getWordsBank: function (params) {
    return http.get("/backend/api/v1/word/", params);
  },
  getWordsBank2: function (params) {
    return http.get("/backend/api/v1/word/getword", params);
  },
  detailWordsBank: function (params) {
    return http.get("/backend/api/v1/word", params);
  },
  editWordsBank: function (params) {
    return http.post("/backend/api/v1/word/update", params);
  },
  deleteWordsBank: function (params) {
    return http.delete("/backend/api/v1/word/delete", params);
  },
};

export default questionBank;
