import http from "../http";

const common = {
    /*文件上传*/
    uploadFile: function (params) {
        return http.post('/backend/api/v1/media/add', params)
    },
    loginApi:function(params){
        return http.post('/backend/api/v1/login',params)
    },
    userApi:function(params){
        return http.get('/backend/api/v1/user',params)
    },

    menuTreeApi:function(params){
        return http.get('/backend/api/v1/coursetree',params)
    },

    manageTyle:function(params){
        return http.get('/backend/api/v1/administrator_role',params)
    }
};

export default common
