import http from "../http";

const notify = {
  /*文件上传*/
  addNotify: function (params) {
    return http.post("/backend/api/v1/news/add", params);
  },
  getNotify: function (params) {
    return http.get("/backend/api/v1/news", params);
  },
  detailNotify: function (params) {
    return http.get("/backend/api/v1/news", params);
  },
  editNotify: function (params) {
    return http.post("/backend/api/v1/news/update", params);
  },
  deleteNotify: function (params) {
    return http.delete("/backend/api/v1/news/delete", params);
  },
};

export default notify;
