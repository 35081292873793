
import acount from './api/acount';
import banner from './api/banner';
import book from './api/book';
import common from './api/common';
import course from './api/course';
import material from './api/material';
import menuType from './api/menuType';
import generalMenu from './api/generalMenu';
import notify from './api/notify';
import press from './api/press';
import questionBank from './api/questionBank';
import readingBank from './api/wordsBank';
import wordsBank from './api/wordsBank';
import store from './api/store';
import test from './api/test';
import user from './api/user';
import version from './api/version';
import famouse from './api/famouse';


//设置个对象，就不用一个个暴露了，直接暴露对象
let api = {
    ...acount,
    ...banner,
    ...book,
    ...common,
    ...course,
    ...material,
    ...menuType,
    ...generalMenu,
    ...notify,
    ...press,
    ...questionBank,
    ...store,
    ...test,
    ...user,
    ...version,
    ...readingBank,
    ...wordsBank,
    ...famouse

};

//暴露出这个对象
export default api;

